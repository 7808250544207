import NavbarFooter from "./navbar-footer";
import SocialIcons from "./social-icons-group";

import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import { useState } from "react";

import { useTranslation } from "react-i18next";

import useMediaQuery from '@mui/material/useMediaQuery';
import { createTheme } from '@mui/material/styles'
import NavbarMobile from "./navbar-mobile";

import logo from '../../assets/common/logo.webp'
import { ICategoria } from "../../interfaces/produto/categoria";

export default function FooterPimaco (props: { categorias: ICategoria[] }) {

    const [showMore, setShowMore] = useState<boolean>(false);

    const theme = createTheme();
    const { t } = useTranslation();
    const matches = useMediaQuery(theme.breakpoints.up('lg'));

    return (
        <footer>
            {/* <div className="footer-navbar-container">

                { !matches &&
                    <img
                        src={logo}
                        className="logo"
                        alt="Logo da Pimaco"
                    />
                }

                { matches &&
                    <KeyboardArrowUpIcon
                        onClick={() => setShowMore(!showMore)}
                        className={`${showMore ? 'expanded' : ''}`}
                    />
                }
                
                { matches &&
                    <NavbarFooter
                        setShowMore={setShowMore}
                        showMore={showMore}
                        categorias={props.categorias}
                    />
                }
                <div
                    className="footer-social-icons-container"
                >
                    <SocialIcons />
                </div>
                
            </div> */}
            
            { !matches &&
                <NavbarMobile
                    categorias={props.categorias}
                />
            }
            <div
                className="footer-credits-container"
            >
                <p>&copy; Pimaco 2024. Powered by Beontag</p>
                <div>
                <p style={{ marginTop: 0, fontSize: 10 }}>{process.env.REACT_APP_APP_VERSION}</p>
                </div>
                
            </div>
        </footer>
    )
}