import { Button, Grid, InputAdornment, TextField } from "@mui/material";
import { useState } from "react";
import iconePimaco from "../../../assets/logo-ETQVRSEX.webp";

import sUsuario from "../../../services/usuarioService";

import { useMessage } from "../../../context/alertContext/context";
import { useTranslation } from "react-i18next";

function EsqueciSenha(props: { modifyActiveComponent: Function }) {
  const [email, setEmail] = useState<string>("");

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { showAlertApp } = useMessage();
  const { t } = useTranslation("editor");
  const handleSubmit = async (e: any) => {
    e.preventDefault();

    setIsLoading(true);

    await sUsuario
      .recoverPassword({ email: email })
      .then((resp) => {
        setIsLoading(false);
        showAlertApp(
          t(
            "Caso esse usuário exista em nosso banco de dados, enviaremos um email com uma senha temporária"
          ),
          "success",
          5000
        );
        props.modifyActiveComponent("login");
      })
      .catch((err) => {
        setIsLoading(false);
        showAlertApp(
          t(
            "Houve um erro ao tentar recuperar a senha, tente novamente mais tarde"
          ),
          "error",
          5000
        );
      });
  };

  return (
    <div className="editor-login">
      <div className="editor-login-container">
        <div className="editor-login-authentication-image">
          <a href="https://www.pimaco.com.br">
            <img
              src={iconePimaco}
              alt=""
              className="editor-login-authentication-image-img"
            />
          </a>
        </div>

        <p className="page-paragraph">
          {t("Informe seu e-mail para solicitar uma nova senha provisória.")}
        </p>

        <Grid container rowSpacing={2} className="editor-login-authentication">
          <Grid item xs={12}>
            <h1 className="editor-login-authentication-text">
              {t("Esqueci minha senha")}
            </h1>
          </Grid>

          <Grid item xs={12}>
            <TextField
              id="email"
              type="email"
              name="email"
              aria-label="Email:"
              variant="filled"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              fullWidth
              InputProps={{
                startAdornment: (
                  <InputAdornment
                    position="start"
                    disablePointerEvents
                    className="editor-login-authentication-fields-adornment"
                  >
                    <span className="form-label">{t("E-mail:")}</span>
                  </InputAdornment>
                ),
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <Button
              disabled={isLoading}
              type="submit"
              variant="contained"
              className="form-contato-submit-button btn btn-contained-primary"
              disableElevation
              onClick={(e) => handleSubmit(e)}
            >
              {t("Enviar")}
            </Button>

            <br />
            <br />

            <p
              role="button"
              className="editor-login-paragraphs"
              onKeyDown={() => props.modifyActiveComponent("login")}
              onClick={() => props.modifyActiveComponent("login")}
            >
              {t("Retornar para a tela de autenticação")}
            </p>
          </Grid>
        </Grid>
      </div>
    </div>
  );
}

export default EsqueciSenha;
