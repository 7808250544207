import {
  Alert,
  Dialog,
  DialogContent,
  DialogTitle,
  Divider,
  IconButton,
} from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import { Trans, useTranslation } from "react-i18next";

const PWAInstall = (props: {
  open: boolean;
  handleCloseModal: Function;
  buttonRef: React.MutableRefObject<null>;
  children: JSX.Element;
}) => {
  let isChromium = false;

  if (
    typeof window !== "undefined" &&
    navigator &&
    navigator.userAgent &&
    navigator.userAgent.indexOf("Chrome") != -1
  ) {
    isChromium = true;
  }
  const { t } = useTranslation("editor");
  return (
    <>
      <Dialog
        open={props.open}
        onClose={() => props.handleCloseModal()}
        scroll="paper"
        maxWidth={"md"}
      >
        <DialogTitle>
          <IconButton onClick={() => props.handleCloseModal()}>
            <CloseIcon />
          </IconButton>
        </DialogTitle>
        <DialogContent dividers>

          <p>
          <Trans t={t} i18nKey="softwareNotCompatibleWithOldVersion">
            <strong className="modal-alert-strong">Atenção: </strong> O software atual não é compatível com as artes/arquivos da versão anterior, sendo necessário criar um novo arquivo para que o software atualizado funcione corretamente.
          </Trans>
          </p>

          <Divider />

          {!isChromium && (
            <Alert severity="warning" variant="filled">
              <Trans t={t} i18nKey="browserNotSupported">
                Atenção! Este navegador não possui suporte para a instalação da
                aplicação Editor Pimaco. Por favor, utilize o
                <strong>Google Chrome</strong> ou
                <strong>Microsoft Edge</strong>.
              </Trans>
            </Alert>
          )}
          <p>
            {t("Para instalar o Editor Pimaco no seu computador:")}
            <ol>
              <li>{t('Clique no botão "Instalar" abaixo')}</li>
              <li>
                {t(
                  'Na telinha que abrirá para confirmar a instalação com a pergunta "Instalar app?", confirme escolhendo a opção "Instalar"'
                )}
              </li>
              <li>
                {t(
                  "O seu computador automaticamente irá abrir a versão instalada"
                )}
              </li>
            </ol>
          </p>

          {props.children}
        </DialogContent>
      </Dialog>
    </>
  );
};

export default PWAInstall;
